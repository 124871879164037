document.addEventListener('turbolinks:load', function() {
  var map = document.querySelector("#map");
  if (!map) { return; };

  if (!map.style.height) {
    var height = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    map.style.height = height + "px";
  }

  var mymap = L.map('map').setView([48.1070226, -123.4542519 ], 10);
  L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYW5kcmV3cGJyZXR0IiwiYSI6ImNraTB0ZmJ3dDBjMGgycW41NHNsamtvOXQifQ.HvkL1LdnGZSG7rUijFROZg', {
      attribution: 'Data &copy;<a href="https://www.openstreetmap.org/">OSM</a> Contributors <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC/SA</a> Imagery ©<a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 18,
      id: 'mapbox/streets-v11',
      tileSize: 512,
      zoomOffset: -1,
      accessToken: 'your.mapbox.access.token'
  }).addTo(mymap);

  var geoJSON = {
    "type": "FeatureCollection",
    "name": "Places",
    "crs": {
      "type": "name",
      "properties": {
        "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
      }
    },
    "features": [
      {
        "type": "Feature",
        "properties": {
          "name": "The Wedding!",
          "description": "43 Black Diamond Road"
        },
        "geometry": {
          "type": "Point",
          "coordinates": [
            -123.4542519,
            48.1070226
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Little River Trail",
          "description": null
        },
        "geometry": {
          "type": "Point",
          "coordinates": [
            -123.5013422,
            48.057161
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Victoria, BC",
          "description": null
        },
        "geometry": {
          "type": "Point",
          "coordinates": [
            -123.3656439,
            48.4332326
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Port Townsend",
          "description": null
        },
        "geometry": {
          "type": "Point",
          "coordinates": [
            -122.7594169,
            48.1156688
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "San Juan Islands",
          "description": null
        },
        "geometry": {
          "type": "Point",
          "coordinates": [
            -122.8957484,
            48.5774684
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Pyramid Peak",
          "description": null
        },
        "geometry": {
          "type": "Point",
          "coordinates": [
            -123.8081286,
            48.0748771
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Ozette Triangle",
          "description": null
        },
        "geometry": {
          "type": "Point",
          "coordinates": [
            -124.6640601,
            48.1533822
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Shi Shi Beach",
          "description": null
        },
        "geometry": {
          "type": "Point",
          "coordinates": [
            -124.6777476,
            48.276848
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Cape Flattery",
          "description": null
        },
        "geometry": {
          "type": "Point",
          "coordinates": [
            -124.7155131,
            48.3832145
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Rialto",
          "description": null
        },
        "geometry": {
          "type": "Point",
          "coordinates": [
            -124.6363805,
            47.9206362
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Second Beach",
          "description": null
        },
        "geometry": {
          "type": "Point",
          "coordinates": [
            -124.618356,
            47.8863428
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Ruby Beach",
          "description": null
        },
        "geometry": {
          "type": "Point",
          "coordinates": [
            -124.4174399,
            47.7142935
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Hoh Rainforest",
          "description": null
        },
        "geometry": {
          "type": "Point",
          "coordinates": [
            -123.9227118,
            47.8652618
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Dungeness Spit",
          "description": null
        },
        "geometry": {
          "type": "Point",
          "coordinates": [
            -123.1962409,
            48.1322112
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Port Williams State Park Beach",
          "description": null
        },
        "geometry": {
          "type": "Point",
          "coordinates": [
            -123.0470647,
            48.0960314
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Hurricane Ridge",
          "description": null
        },
        "geometry": {
          "type": "Point",
          "coordinates": [
            -123.4902468,
            47.9739063
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Salt Creek",
          "description": null
        },
        "geometry": {
          "type": "Point",
          "coordinates": [
            -123.7019826,
            48.1595019
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Freshwater Bay",
          "description": null
        },
        "geometry": {
          "type": "Point",
          "coordinates": [
            -123.643252,
            48.1466833
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Spruce Railroad Trail",
          "description": null
        },
        "geometry": {
          "type": "Point",
          "coordinates": [
            -123.8025538,
            48.0917908
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Sol Duc Falls",
          "description": null
        },
        "geometry": {
          "type": "Point",
          "coordinates": [
            -123.9894597,
            48.0534652
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Discovery Trail",
          "description": null
        },
        "geometry": {
          "type": "Point",
          "coordinates": [
            -123.4251782,
            48.1179675
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Lake Angeles",
          "description": null
        },
        "geometry": {
          "type": "Point",
          "coordinates": [
            -123.432109,
            48.0393197
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Royal Basin",
          "description": null
        },
        "geometry": {
          "type": "Point",
          "coordinates": [
            -123.2135066,
            47.8306463
          ]
        }
      }
    ]
  };
  function onEachFeature(feature, layer) {
    layer.bindPopup(feature.properties.name);
  };

  L.geoJSON(geoJSON, {
    onEachFeature: onEachFeature,
    pointToLayer: function (feature, latlng) {
      return L.marker(latlng, {
        radius: 8,
        fillColor: "#ff7800",
        color: "#000",
        weight: 1,
        opacity: 1,
        fillOpacity: 0.8
      });
    }
  }).bindPopup(function(layer) {
    return layer.feature.properties.name
  }).addTo(mymap);
});
